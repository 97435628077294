import React, { type FC } from 'react';

type PrevNextButtonPropType = {
    prefixClassName?: string;
    disabled: boolean;
    onClick: () => void;
    color?: string;
};

export const PrevButton: FC<PrevNextButtonPropType> = (props) => {
    const { prefixClassName = '', disabled, onClick, color } = props;

    return (
        <button
            data-testid="carousel-button-prev"
            className={`${prefixClassName}carousel__arrow ${prefixClassName}carousel__arrow-prev`}
            onClick={onClick}
            disabled={disabled}
        >
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.46746 2.17179L2.73185 8.90738C2.23502 9.40421 1.42951 9.40421 0.932679 8.90738C0.435852 8.41055 0.435852 7.60504 0.932679 7.10821L7.66829 0.37262C8.16511 -0.124207 8.97063 -0.124207 9.46746 0.37262C9.96428 0.869447 9.96428 1.67496 9.46746 2.17179Z"
                    fill={color ?? '#23292D'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.46746 13.8282L2.73185 7.09262C2.23502 6.59579 1.42951 6.59579 0.932679 7.09262C0.435852 7.58945 0.435852 8.39496 0.932679 8.89179L7.66829 15.6274C8.16511 16.1242 8.97063 16.1242 9.46746 15.6274C9.96428 15.1306 9.96428 14.325 9.46746 13.8282Z"
                    fill={color ?? '#23292D'}
                />
            </svg>
        </button>
    );
};

export const NextButton: FC<PrevNextButtonPropType> = (props) => {
    const { prefixClassName = '', disabled, onClick, color } = props;

    return (
        <button
            data-testid="carousel-button-next"
            className={`${prefixClassName}carousel__arrow ${prefixClassName}carousel__arrow-next`}
            onClick={onClick}
            disabled={disabled}
        >
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.532544 2.17179L7.26815 8.90738C7.76498 9.40421 8.57049 9.40421 9.06732 8.90738C9.56415 8.41055 9.56415 7.60504 9.06732 7.10821L2.33171 0.37262C1.83489 -0.124207 1.02937 -0.124207 0.532544 0.37262C0.035718 0.869447 0.035718 1.67496 0.532544 2.17179Z"
                    fill={color ?? '#23292D'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.532544 13.8282L7.26815 7.09262C7.76498 6.59579 8.57049 6.59579 9.06732 7.09262C9.56415 7.58945 9.56415 8.39496 9.06732 8.89179L2.33171 15.6274C1.83489 16.1242 1.02937 16.1242 0.532544 15.6274C0.035718 15.1306 0.035718 14.325 0.532544 13.8282Z"
                    fill={color ?? '#23292D'}
                />
            </svg>
        </button>
    );
};
