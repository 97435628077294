import { type ProductProps, type OnProductActionProps, ProductTile, type SkuAction } from '@petcircle/component-library';
import { trackEvent } from '@tracking/track-event.ts';
import { EETransport, EEventName, EEventSource, EEventType } from '@tracking/models.ts';
import React, { useMemo } from 'react';
import { getSkuAction, type ConstructorProduct } from '@widgets/product-carousel/helpers.ts';

type ProductCarouselSlideItemProps = {
    product: ProductProps;
    showAdPrice: boolean;
    dataSection?: string;
    constructorProducts?: ConstructorProduct[];
};

export const ProductCarouselSlideItem = ({ product, showAdPrice, dataSection, constructorProducts }: ProductCarouselSlideItemProps) => {
    const { skus, activeSku } = product;
    const [statusState, setStatus] = React.useState('addToCart' as SkuAction);

    // // Find the matching product in constructorProducts based on the activeSku of the current product
    const matchedConstructorProduct = useMemo(() => {
        if (!constructorProducts || constructorProducts.length === 0 ) return null;
        return constructorProducts.find(cp => cp.productSkus.some((sku: { sku: string; }) => sku.sku === activeSku));
    }, [constructorProducts, activeSku]);

    const augmentedProduct: ProductProps = useMemo(
        () => ({
            ...product,
            activeSku,
            brandlessName: `${product.brandlessName}, ${skus.find((sku) => sku.sku === activeSku)?.displayName}`,
            skus: skus.map((skuProps) => ({
                ...skuProps,
                action: getSkuAction(skuProps, statusState),
            })),
        }),
        [product, statusState]
    );

    const onAddToCart = ({ status }: { status: SkuAction }) => {
        setStatus(status);
    };

    // Conditionally build the data attributes if matchedConstructorProduct exists
    const constructorDataAttributes = matchedConstructorProduct
        ? {   'data-cnstrc-item': 'Recommendation',
              'data-cnstrc-item-id': `${matchedConstructorProduct.productId}`,
              'data-cnstrc-item-name': matchedConstructorProduct.productName,
              'data-cnstrc-strategy-id': matchedConstructorProduct.strategyId,
              'data-cnstrc-item-variation-id': matchedConstructorProduct.productSkus[0].sku,
          }
        : {};

    return (
        <div
            data-testid="carousel-slide"
            className="base-carousel__slide product-carousel__slide"
            key={product.activeSku}
            {...constructorDataAttributes}
        >
            <ProductTile
                product={augmentedProduct}
                showSkuSelector={false}
                showAdPrice={showAdPrice}
                onAction={(data: OnProductActionProps) =>
                    StaticWebsite.onCarouselAddToCart(
                        { isAddToAutoDelivery: showAdPrice, ...data },
                        product,
                        onAddToCart
                    )
                }
                onTileClick={(product: ProductProps) =>
                    trackEvent({
                        eventName: EEventName.Navigation,
                        eventOutcome: `${dataSection ?? 'product collections'} clicked`,
                        pageSection: dataSection ?? 'product collections',
                        itemNameValue: product.activeSku,
                        customOne: product.link,
                        componentItem: 'product box',
                        eventType: EEventType.Click,
                        eventSource: EEventSource.User,
                        transport: EETransport.Beacon,
                    })
                }
            />
        </div>
    );
};
