import React, { useEffect, useState } from 'react';
import type { EmblaOptionsType } from 'embla-carousel-react';
import { GeoCustomEvents, ProductRequestType } from '../models.ts';
import { ProductCarousel } from '@widgets/product-carousel/ProductCarousel.tsx';
import { filterProductsWithAvailableSKU, getProducts, type ConstructorProduct } from './helpers.ts';
import { mapLegacyProducts, type ProductProps, ThemeProvider } from '@petcircle/component-library';

const DEFAULT_DELIVERY_CODE = 'SYD';

const Spinner = () => (
    <div className="cms-product-carousel-loading">
        <div className="loader">
            <span className="a"></span>
            <span className="b spin">
                <span className="c"></span>
            </span>
        </div>
    </div>
);

/**
 * This component will create a collections of products based on the collections provided.
 * @returns
 */
export const ProductCarouselWrapper = (props: {
    collections: string[];
    dataSection: string;
    showAdPrice?: boolean;
    constructorProducts?: ConstructorProduct[];
}) => {
    const { collections, dataSection, showAdPrice = false , constructorProducts = []} = props;
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [staticWebsiteInitialized, setStaticWebsiteInitialized] = useState(typeof StaticWebsite !== 'undefined');
    async function getCollections(collectionProducts: string[]) {
        if (!staticWebsiteInitialized) return;

        setLoading(true);
        try {
            const locationCookie = StaticWebsite.getLocation();

            const data = await getProducts({
                productRequestType: ProductRequestType.SKU,
                products: collectionProducts,
                deliveryCode: locationCookie?.deliveryCode || DEFAULT_DELIVERY_CODE,
                includes: ['tag-details'],
            });

            const productsWithAvailableSkus = filterProductsWithAvailableSKU(data?.products);
            const mappedProducts: ProductProps[] = mapLegacyProducts(productsWithAvailableSkus);
            const products = mappedProducts.filter((product) => product.skus.length > 0);
            setProducts(products);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await getCollections(collections);

            document.addEventListener(GeoCustomEvents.locationUpdateDone, () => {
                getCollections(collections);
            });
            document.addEventListener(GeoCustomEvents.SetUserLocationOnHeader, () => {
                getCollections(collections);
            });

            return () => {
                document.removeEventListener(GeoCustomEvents.locationUpdateDone, () => {
                    getCollections(collections);
                });
                document.removeEventListener(GeoCustomEvents.SetUserLocationOnHeader, () => {
                    getCollections(collections);
                });
            };
        })();
    }, [staticWebsiteInitialized]);

    // This is a temporary workaround.
    // Sometimes the StaticWebsite object provided by Shiba has not yet been loaded when the
    // ProductCollections carousel renders. This means that the carousel fails due to the object not
    // existing. A proper fix for this is for the Shiba provided JS to fire an event when it's ready
    // and this code listen to that event to trigger it's loading.
    useEffect(() => {
        if (staticWebsiteInitialized) return;

        const checkStaticWebsiteInitialized = () => {
            if (typeof StaticWebsite !== 'undefined') {
                setStaticWebsiteInitialized(true);
                return true;
            }
            setTimeout(checkStaticWebsiteInitialized, 100);
            return false;
        };

        checkStaticWebsiteInitialized();
    }, [staticWebsiteInitialized]);

    if (loading || !products || !staticWebsiteInitialized) return <Spinner />;

    const OPTIONS: EmblaOptionsType = {
        dragFree: true,
        containScroll: 'trimSnaps',
    };

    return (
        <ThemeProvider>
            <ProductCarousel
                products={products}
                constructorProducts={constructorProducts}
                options={OPTIONS}
                dataSection={dataSection}
                showAdPrice={showAdPrice}
            />
        </ThemeProvider>
    );
};
