import type { ProductModel, ProductSkuModel, SkuAction, SkuProps } from '@petcircle/component-library';
import type { ProductRequestType } from '@widgets/models';
import isEmpty from 'lodash/isEmpty';

const Endpoints = Object.freeze({
    Products: '/products',
});

type GetProductIncludes = 'extra-images' | 'tag-details' | 'description';

export type ConstructorProduct = {
        activeSku: string;
        brand: string;
        brandlessName: string;
        id: string;
        imageAlt: string;
        imageUrl: string;
        isVetDietProduct?: boolean;
        productSkus: SkuProps[];
        link: string;
        productId?: string;
        productName?: string;
        strategyId?: string;
        review: {
            average: number;
            count: number;
        };
        skus: SkuProps[];
}

export const getProducts = async (data: {
    productRequestType: ProductRequestType;
    /** this is a list of sku's or list of product names you are requesting */
    products: string[];
    deliveryCode: string;
    includes: GetProductIncludes[];
}) => {
    const { productRequestType, deliveryCode, products, includes } = data;
    const searchParams = new URLSearchParams();

    if (deliveryCode) {
        searchParams.append('delivery-code', deliveryCode);
    }

    if (includes.length) {
        searchParams.append('include', includes.toString());
    }

    if (products.length) {
        products.forEach((product) => {
            searchParams.append(`${productRequestType}`, product);
        });
    }

    const response = await fetch(`${import.meta.env.PUBLIC_BASE_API_URL}${Endpoints.Products}?${searchParams}`);
    return await response.json();
};

export const getSkuAction = (skuProps: SkuProps, status: SkuAction): SkuAction => {
    if (skuProps.isSoldOut) {
        return 'soldOut';
    }

    return status;
};

export const filterProductsWithAvailableSKU = (products: ProductModel[]): ProductModel[] =>
    products
        .map((product: ProductModel) => {
            // Only display available skus
            const availableSkus = product.productSkus.filter((sku: ProductSkuModel) => sku.isAvailable && sku.inStock);
            // If there is no default sku, use the smallest one
            if (
                !isEmpty(availableSkus) &&
                availableSkus.filter((sku: ProductSkuModel) => sku.defaultSku).length === 0
            ) {
                availableSkus[0].defaultSku = true;
            }
            return {
                ...product,
                productSkus: availableSkus,
            };
        })
        .filter((product: ProductModel) => !isEmpty(product.productSkus));
