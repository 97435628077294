import './ProductCarousel.scss';
import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel, { type EmblaOptionsType } from 'embla-carousel-react';
import type { ProductProps } from '@petcircle/component-library';
import { NextButton, PrevButton } from '@widgets/product-carousel/CarouselButtons';
import { ProductCarouselSlideItem } from '@widgets/product-carousel/ProductCarouselSlideItem.tsx';
import type { ConstructorProduct } from '@widgets/product-carousel/helpers.ts';

type CarouselProps = {
    products: ProductProps[];
    options?: EmblaOptionsType;
    dataSection?: string;
    showAdPrice: boolean;
    constructorProducts?: ConstructorProduct[];
};

export const ProductCarousel: React.FC<CarouselProps> = (props: CarouselProps) => {
    const { products, options, dataSection, showAdPrice, constructorProducts } = props;
    const [carouselRef, carouselApi] = useEmblaCarousel(options);
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => carouselApi && carouselApi.scrollPrev(), [carouselApi]);
    const scrollNext = useCallback(() => carouselApi && carouselApi.scrollNext(), [carouselApi]);

    const onSelect = useCallback(() => {
        if (!carouselApi) return;
        setPrevBtnEnabled(carouselApi.canScrollPrev());
        setNextBtnEnabled(carouselApi.canScrollNext());
    }, [carouselApi]);

    useEffect(() => {
        if (!carouselApi) return;
        onSelect();

        let carouselInitialized = false;

        carouselApi.on('init', () => {
            carouselInitialized = true;
        });
        carouselApi.on('select', onSelect);
        carouselApi.on('reInit', onSelect);

        /**
         * Note: If carousel is not firing onInit event, run reInit to load the asset files incl. CSS/JS to make the carousel UI functionality work
         */
        if (!carouselInitialized) {
            setTimeout(() => {
                carouselApi.reInit();
            }, 500);
        }
    }, [carouselApi, carouselRef, onSelect]);

    return (
        <div className="base-carousel product-carousel">
            <div className="product-carousel__viewport" ref={carouselRef}>
                <div className="base-carousel__container product-carousel__container">
                    {products.map((product) => (

                        <ProductCarouselSlideItem
                            constructorProducts={constructorProducts}
                            key={product.activeSku}
                            product={product}
                            showAdPrice={showAdPrice}
                            dataSection={dataSection}
                        />
                    ))}
                </div>
            </div>

            <PrevButton prefixClassName="product-" disabled={!prevBtnEnabled} onClick={scrollPrev} />
            <NextButton prefixClassName="product-" disabled={!nextBtnEnabled} onClick={scrollNext} />
        </div>
    );
};
