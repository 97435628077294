import type { ContentCarouselItem, Link, Segmentation, MediaModel } from '@components/models.ts';

export enum ProductRequestType {
    SKU = 'sku',
    ProductName = 'productName',
}

export enum GeoCustomEvents {
    SetUserLocationOnHeader = 'setUserLocationOnHeader',
    SetUserLocationOnProductPage = 'setUserLocationOnProductPage',
    setLocationDone = 'setLocationDone',
    locationUpdateDone = 'locationUpdateDone',
}

export interface ContentCarousel {
    id: number;
    title?: string;
    slug: string;
    page_model: string;
    link_details?: Link;
    items: ContentCarouselItem[];
    segment_item?: {
        id: number;
        segmentation: Segmentation | null;
        items: ContentCarouselItem[];
    };
}


export interface ArticleItem {
    id: number;
    title: string;
    url_path: string;
    image: MediaModel;
    categories: {
        name:string
    }[]
}
